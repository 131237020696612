import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { mergeMap, Observable, of } from "rxjs";
import { AuthService } from "@auth0/auth0-angular";
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class UserAccountService {

    private url: string = "@selfApi/userProfileService";

    constructor(private httpClient: HttpClient,
                private authService: AuthService) {
    }

    getAllPermissions() {
        return this.authService.getAccessTokenSilently()
            .pipe(mergeMap(token => {
                let decodedToken = jwtDecode(token);
                let permissions = decodedToken["permissions"] as string[];
                return of(permissions);
            }));
    }

    get() {
        return this.httpClient.get<any>(this.url);
    }

    post(): Observable<any> {
        return this.httpClient.post<any>(this.url, {});
    }

    put(formVal: any) {
        return this.httpClient.put<any>(this.url, formVal);
    }

    delete() {
        return this.httpClient.delete(this.url);
    }
}
